import { useSelector } from "react-redux";
import configData from "../../../config.js";
import { Button, Card, InputLabel } from "@mui/material";
import axios from "axios";
import FileSaver from "file-saver";
import { DatePicker } from "antd";
import { useState } from "react";
import { InfoOutlined } from "@mui/icons-material";
import moment from "moment";

const HistoryContent = () => {
  const project = useSelector((state) => state.project);
  const [selectedDates, setSelectedDates] = useState([]);
  const [DatesStatus, setDatesStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const MAXIMUM_DOWNLOADABLE_FILES = 5;
  const hasError = DatesStatus === "error";

  function axiosDownloadFile(date) {
    setIsLoading(true)
    return axios({
      url: `${configData.SERVER_URL}History/CSV/${project.id}`,
      params: {
        date: moment(date.$d).format("YYYY/MM/DD"),
      },
      method: "GET",
      responseType: "json",
    })
      .then((response) => {
        const byteCharacters = atob(response.data.fileContents); // Convert base64 to binary
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: "application/vnd.ms-excel",
        });
        FileSaver.saveAs(blob, `${project.description} history - ${moment(date.$d).format("YYYY/MM/DD")}.csv`);
      setSelectedDates([])
      setIsLoading(false)
    })
    .catch((error) => {
      console.log("error: ", error);
      setIsLoading(false)
      });
  }

  const handleDateChange = (dates) => {
    if (dates.length <= MAXIMUM_DOWNLOADABLE_FILES) {
      setDatesStatus("");
      setSelectedDates(dates);
    } else {
      setSelectedDates(dates);
      setDatesStatus("error");
    }
  };

  const handleSubmit = () => {
    selectedDates.map((date) => axiosDownloadFile(date));
  };

  // Disable future dates
  const disableFutureDates = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          position: "relative",
          borderRadius: "30px",
          width: "90%",
          maxWidth: "1080px",
          aspectRatio: 2,
          padding: "20px 40px",
          marginY: "20px",
        }}
      >
        <h2>Download daily history as CSV</h2>
        <InputLabel sx={{color: hasError ? "red" : "#8B8B8B",}}>{'Select date(s):'}</InputLabel>
        <DatePicker
          status={DatesStatus}
          format={"YYYY/MM/DD"}
          value={selectedDates} // Use value instead of defaultValue
          onChange={handleDateChange}
          onCalendarChange={handleDateChange}
          multiple
          maxTagCount="responsive"
          disabled={isLoading}
          size="large"
          allowClear={false}
          disabledDate={disableFutureDates} // Disable future dates
        />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            color: hasError ? "red" : "#8B8B8B",
          }}
        >
          <InfoOutlined /> You can select up to {MAXIMUM_DOWNLOADABLE_FILES}{" "}
          days
        </p>
        <Button
          sx={{ width: "100%", margin: "40px 0px " }}
          variant="contained"
          size="large"
          onClick={handleSubmit}
          disabled={selectedDates.length === 0 || hasError || isLoading}
        >
        Download{" "}
          {selectedDates.length !== 0 &&
            !hasError &&
            `(${selectedDates.length} CSV ${
              selectedDates.length === 1 ? "file" : "files"
            })`}
        </Button>
      </Card>
    </div>
  );
};

export default HistoryContent;
