import React from "react";
import config from "../../../../config";
import { useSelector } from "react-redux";
import { useGetData } from "../../../../hooks/hooks";
import ScannerGraph from "./ScannerGraph";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function ScannersCard() {
  const project = useSelector((state) => state.project);
  const endpoint = `${config.SERVER_URL}Scans/Summary/${project.id}`;
  const selectedDate = moment().format("YYYY-MM-DD Z");
  const navigate = useNavigate();
  const {
    data,
    isLoading,
      } = useGetData({
    endpoint,
    refetchInterval: 5000,
    parameters: { selectedDate },
    requestId: "dashboard-scanners",
  });
  if (isLoading) {
    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading scanners data...
      </div>
    );
  }
  if (data?.pages[0]?.data.results.length === 0) {
    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        
      >
        No scanners data
      </div>
    );
  }
  return (
    <div onClick={()=>navigate(`/${project.name.replace(/ /g, "-")}/Scanners`)} style={{ position: "relative", cursor:"pointer", height: "100%" }}>
      <strong>Scanner statistics</strong>
      <ScannerGraph data={data} />
    </div>
  );
}
