import React from "react";
import { useSelector } from "react-redux";

import styled from "@emotion/styled";
import { ThreeDRotation } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function Layout() {
  const project = useSelector((state) => state.project);
  const navigate = useNavigate()
  return (
    
      <div onClick={()=>navigate(`/${project.name.replace(/ /g, "-")}/3D-Layout`)} style={{ height: "100%", width: "100%", textAlign:"center", color:"white",    }}>
        <ThreeDRotation style={{color:"gray",width:"50%", position:"absolute", top: "50%",  left: "50%", transform: "translate(-50%, -50%) scale(4)"}}/>
      <ResponsiveImage style={{opacity:0.3}} src={project.image}/>
        </div>
  );
}
const ResponsiveImage = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  flex: 1;
`;