import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";

export default function LoginModal({ selectedMachine, handleClose }) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const fetchToken = async (id, username, password) => {
    try {
      const response = await axios.post(`${config.SERVER_URL}DialIn/${id}`, {
        username,
        password,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  return (
    <Dialog
      open={selectedMachine ? true : false}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();
          const results = await fetchToken(
            selectedMachine.id,
            username,
            password
          );
          navigate({
            pathname: "machine/connection",
            search: createSearchParams({
              name: selectedMachine.machineName,
              token: results.data.results[0].token,
            }).toString(),
          });
          handleClose();
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {`Enter credentials to connect to "${selectedMachine?.machineName}"`}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          name="dialin-username"
          label="Username"
          type="text"
          fullWidth
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          variant="filled"
        />
        <TextField
          required
          onChange={(e) => setPassword(e.target.value)}
          margin="dense"
          name="dialin-password"
          label="Password"
          type="password"
          fullWidth
          value={password}
          variant="filled"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button type="submit" variant="contained" autoFocus>
          Connect
        </Button>
      </DialogActions>
    </Dialog>
  );
}
