import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import KANSILoader from "../../Shared/KANSILoader";
import PARLoader from "../../Shared/PARLoader";
import jwt_decode from "jwt-decode";
import toast from "react-hot-toast";
import { Info } from "@mui/icons-material";

const ModelContent = () => {
  const project = useSelector((state) => state.project);
  const [loading, setLoading] = useState(true);
  const token = sessionStorage.getItem("accessToken");
  const userType = token
  ? jwt_decode(sessionStorage.getItem("accessToken"))?.userType
  : "";

  useEffect(() => {
    toast.success("You are about to open a page that uses external data, be aware that the page's performance depends on your device", { id: "3d layout",position:"top-right",icon:<Info/>,duration:5000 })
    const timer = setTimeout(() => {
      setLoading(false);
    }, 9000); // 5 seconds

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {loading && (
        <div style={{width:"100%",position:"absolute",height:"100%",background:"white"}}>
       {userType?.toLowerCase().includes("kansi") && (
          <KANSILoader/>
          )}
       {userType?.toLowerCase().includes("csp") && (
          <PARLoader/>
          )}
        </div>
      )} 
      { project.layoutLink ? (
        <iframe
          src={project.layoutLink}
          title="3D Model Viewer"
          width="100%"
          height="100%"
          allowFullScreen
        />
      ) : (
        <h1>No layout</h1>
      )}
    </div>
  );
};

export default ModelContent;
