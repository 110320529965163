import React from "react";
import config from "../../../../config";
import { useSelector } from "react-redux";
import { useGetData } from "../../../../hooks/hooks";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function HistoryCard() {
  const project = useSelector((state) => state.project);
  const endpoint = `${config.SERVER_URL}History/${project.id}`;
  const selectedDate = moment().format("YYYY-MM-DD Z");
  const navigate = useNavigate()
  const {
    data,
    isLoading,
  } = useGetData({
    endpoint,
    refetchInterval: 5000,
    parameters: { pageSize: 10, selectedDate },
    requestId: `${project.id}-short-history`,
  });

  if (isLoading) {
    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#508c9e",
          color: "white",
        }}
      >
        Loading history data...
      </div>
    );
  }
  if (data?.pages[0].data.results.length === 0) {
    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#508c9e",
          color: "white",
        }}
      >
        No history data
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        overflowY: "auto",
        fontSize: "18px",
        background: "#508c9e",
        color: "white",
      }}
      onClick={()=>navigate(`/${project.name.replace(/ /g, "-")}/History`)}
    >
      <h3 style={{ padding: "5px", margin: "0px", textAlign: "left" }}>
        History:
      </h3>
      <div style={{ height: "1px", background: "white", width: "100%" }} />
      <div className="">
        {data?.pages[0].data.results.map((history) => (
          <>
            <div key={history.id} style={{ padding: "10px 0px" }}>
              <div
                style={{
                  display: "flex",
                  padding: "5px 3px",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  {history.objectType}: {history.objectId}
                </span>
                <span>
                  {moment(history.createdAt).format("YYYY/MM/DD HH:mm:ss")}
                </span>
                <span>Location: {history.translation.replace(/\^/g, '')||history.location}</span>
              </div>
              <p style={{ padding: "5px 3px", margin: "0px" }}>
                <strong>{history.changes}</strong>
              </p>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: "20%",
                  opacity: 0.2,
                  width: "100%",
                  background:
                    "linear-gradient(to bottom, rgba(41, 41, 41, 0), #292929)",
                  pointerEvents: "none", // Allow scrolling interaction below this div
                }}
              />
            </div>
            <div
              style={{ height: "1px", background: "white", width: "100%" }}
            />
          </>
        ))}
      </div>
    </div>
  );
}
