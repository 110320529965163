import axios from "axios";
import React, { useState } from "react";
import configData from "../../config.js";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Person, WindowSharp } from "@mui/icons-material";
import LoginModal from "./LoginModal.jsx";
export const fetchMachines = async () => {
  try {
    const response = await axios.get(`${configData.SERVER_URL}DialIn`);
    return response.data;
  } catch (error) {
    // Pass the error to React Query
    throw error;
  }
};
export default function DialinContent() {
  const { data, isLoading } = useQuery(["dial-in"], fetchMachines, {
    refetchInterval: 5000, // Poll every 5000ms (5 seconds)
  });
  const [selectedMachine, setSelectedMachine] = useState(null);

  const handleClose = () => {
    setSelectedMachine(null)
  };

  if (isLoading) {
    return <div className="">Loading..</div>;
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "",
        justifyContent: "",
        margin: "40px",
        width: "100vw",
      }}
    >
      {data.results.map(
        (machine) => (
          <div key={machine.id}>
            <Card
              sx={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                overflow: "visible",
                position: "relative",
                alignItems: "center",
                marginX: "40px",
                textDecoration: "none",
              }}
              variant="contained"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <WindowSharp sx={{ marginX: "5px" }} />
                <span style={{ textDecoration: "none" }}>
                  {machine.machineName}
                </span>
                {machine.isSomeoneConnected && (
                  <Tooltip title="Someone is connected">
                    <Person
                      sx={{
                        position: "absolute",
                        top: -20,
                        right: -20,
                        padding: "10px",
                        background: "white",
                        borderRadius: "100%",
                        border: "1px solid gray",
                      }}
                    />
                  </Tooltip>
                )}
              </div>
              <Button
                onClick={() => setSelectedMachine(machine)}
                disabled={machine.isSomeoneConnected}
                variant="contained"
                sx={{ marginTop: "10px" }}
              >
                Connect
              </Button>
            </Card>
          </div>
        )
      )}
      <LoginModal handleClose={handleClose} selectedMachine={selectedMachine} />
    </div>
  );
}
