import { Error } from '@mui/icons-material'
import { Button } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function ErrorComponent({message}) {
    const navigate = useNavigate();
  return (
    <div style={{display:"flex", flexDirection:"column", background:"#fee2e2", flex:1, alignItems:"center", justifyContent:"center"}}>
    <Error sx={{fill:"#b91c1c",fontSize:"10vw"}}/>    
    <h1 style={{color:"#450a0a"}}>{message}</h1>
    <Button variant='contained' onClick={()=>navigate(-1)}>Back</Button>
    </div>
  )
}
