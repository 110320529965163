import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { ReactComponent as Logo } from "../../imgs/par-innovations.svg";

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
    opacity:1;
  }

  40%, 43% {
    transform: translate3d(0, -30px, 0);
    opacity:0.7
  }

  70% {
    transform: translate3d(0, -15px, 0);
    opacity:1;
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`;

const StyledLogo = styled(Logo)`
  width: 7%;
  aspect-ratio: 1;
  animation: ${bounce} 2s infinite;
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PARLoader = () => {
  return (
    <Container>
      <StyledLogo />
    </Container>
  );
};

export default PARLoader;
