import React, { useState, useEffect, useCallback } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import ScannersCard from "./Scanners/ScannersCard";
import ConnectionStatus from "./ConnectionStatus/ConnectionStatus";
import OperationalCard from "./Operational/OperationalCard";
import HistoryCard from "./History/History";
import TodaysStats from "./Stats/TodaysStats";
import Layout from "./Layout/Layout";

const ReactGridLayout = WidthProvider(RGL);

// Mapping component types to their respective components
const cardComponents = {
  scanner: ScannersCard,
  connectionStatus: ConnectionStatus,
  operational: OperationalCard,
  history: HistoryCard,
  todaysStats: TodaysStats,
  layout: Layout,
};

// Layout configuration
const defaultLayout = [
  { x: 0, y: 0, w: 1, h: 1, i: "connectionStatus" },
  { x: 0, y: 1, w: 1, h: 1, i: "todaysStats" },
  { x: 1, y: 0, w: 2, h: 2, i: "history" },
  { x: 3, y: 0, w: 1, h: 2, i: "layout" },
  { x: 0, y: 2, w: 2, h: 6, i: "operational" },
  { x: 2, y: 2, w: 2, h: 6, i: "scanner" },
];

const NewDashboardContent = () => {
  const [layout, setLayout] = useState(defaultLayout);
  const [rowHeight, setRowHeight] = useState(30);
  const navigate = useNavigate();
  const project = useSelector((state) => state.project);

  // Calculate row height based on window size and layout
  const calculateRowHeight = useCallback(() => {
    const totalHeight = window.innerHeight - 190;
    const totalRows = defaultLayout.reduce((max, item) => Math.max(max, item.y + item.h), 0);
    const calculatedRowHeight = totalHeight / Math.max(totalRows, 1);
    setRowHeight(calculatedRowHeight);
  }, []);

  useEffect(() => {
    calculateRowHeight();
    window.addEventListener("resize", calculateRowHeight);
    return () => {
      window.removeEventListener("resize", calculateRowHeight);
    };
  }, [calculateRowHeight]);

  // Handle layout change
  const handleLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  // Render the dashboard grid
  return (
    <div style={{ height: "100%", position: "relative",   WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none', userSelect: 'none', }}>
      <ReactGridLayout
        layout={layout}
        onLayoutChange={handleLayoutChange}
        className="layout"
        cols={4}
        rowHeight={rowHeight}
        isResizable={false}
        autoSize
        isDraggable={false}
      >
        {Object.entries(cardComponents).map(([key, Component]) => (
          <Card 
            key={key}
            style={{ cursor: "pointer",position:"relative" }}
          >
            <Component />
          </Card>
        ))}
      </ReactGridLayout>
    </div>
  );
};

export default NewDashboardContent;
