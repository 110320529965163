import React from "react";
import { useSelector } from "react-redux";
import { useGetOperational } from "../../../../hooks/hooks.jsx";
import moment from "moment";
import configData from "../../../../config.js";
import Chart from "./Chart.jsx";
import { useNavigate } from "react-router-dom";

export default function OperationalCard() {
  const project = useSelector((state) => state.project);
  const selectedDate = moment().format("YYYY-MM-DD Z");
const navigate = useNavigate()

  const { data, isLoading } = useGetOperational({
    endpoint: `${configData.SERVER_URL}operational/${project.id}`,
    refetchInterval: 5000,
    requestId: `${project.id}-operational`,
    parameters: { selectedDate },
  });
  const chart = data?.filter((decisionPoint) =>
    decisionPoint.translation.includes("^")
  )[0];
  if (isLoading) {
    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading operational data...
      </div>
    );
  }
  if (data.length === 0) {
    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        No operational data
      </div>
    );
  }

  return (
    <>
      
        <div onClick={()=>navigate(`/${project.name.replace(/ /g, "-")}/Operational`)} style={{position: "relative", height: "100%",cursor: "pointer" }}>
        <strong>Operational performance</strong>
          {chart && <Chart data={chart} />}
        </div>
    
    </>
  );
}
