import React, { useEffect, useState } from "react";
import Table from "./Table";
import TableControls from "./TableControls";
import { useGetData } from "../../hooks/hooks";
import moment, { now } from "moment";
import { useSelector } from "react-redux";

const TableWithControls = ({
  requestId,
  parameters,
  gridRef,
  columns,
  toggleColumn,
  columnDefs,
  endpoint,
  hasDateFilter = false,
  hasFirstDateColumn = false,
  pageSize = 10000,
  PassedselectedDate = moment().format("YYYY-MM-DD Z"),
  className,
}) => {
  const [auxId, setAuxId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(PassedselectedDate);

  const {
    data,
    refetch,
    remove,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isRefetching,
  } = useGetData({
    endpoint,
    parameters: { auxId, ...parameters, pageSize, selectedDate },
    requestId,
  });

  const handleModifyAuxId = (value) => {
    setAuxId(value);
  };

  const handleDateChange = (_, date) => {
    remove();
    handleModifyAuxId(null);
    if (date === null) {
      setSelectedDate(moment(now()).format("YYYY-MM-DD Z"));
    } else {
      setSelectedDate(moment(date).format("YYYY-MM-DD Z"));
    }
  };
  useEffect(() => {
    if (data) {
      handleModifyAuxId(data?.pages[0]?.data?.auxId);
    }
  }, [data]);

  const totalServerPages = data?.pages[0]
    ? data?.pages[0]?.data?.pageCount
    : null;

  const totalRows = data?.pages[0] ? data?.pages[0]?.data?.rowsCount : null;
  const allResults = data
    ? data?.pages?.flatMap((page) => page?.data.results)
    : null;
  const { firstDate } = useSelector((state) => state.project);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        ...className,
      }}
    >
      <TableControls
        gridRef={gridRef}
        columns={columns}
        toggleColumn={toggleColumn}
        refetch={refetch}
        remove={remove}
        handleDateChange={handleDateChange}
        hasDateFilter={hasDateFilter}
        handleModifyAuxId={handleModifyAuxId}
        data={data}
      />
      <Table
        data={allResults}
        gridRef={gridRef}
        columnDefs={columnDefs}
        fetchNextPage={fetchNextPage}
        totalServerPages={totalServerPages}
        pageSize={pageSize}
        totalRows={totalRows}
        hasNextPage={hasNextPage}
        isRefetching={isRefetching}
        isLoading={isLoading}
        hasFirstDateColumn={hasFirstDateColumn}
        firstDate={firstDate}
      />
    </div>
  );
};

export default TableWithControls;
