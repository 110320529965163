import { Box } from '@mui/material';
import React, { memo } from 'react'
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

 const ScannerGraph = ({data})=> {
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          const total = payload[0].value + payload[1].value
          const success = payload[0].value 
          const noRead = payload[1].value
          return (
            <div style={{background:"white", padding:"5px" ,border:"1px black solid"}} className="custom-tooltip">
              <strong className="label">{label}</strong>
              <p className="label" style={{color:"#5F9E77"}}>Success: {success + (total===0?"":` (${((success/total) * 100).toFixed(2)}%)`)}</p>
              <p className="intro" style={{color:"#9b2226"}}>No Reads: {noRead + (total===0?"":` (${((noRead/total) * 100).toFixed(2)}%)`)}</p>
              <p className="intro">Total: {total}</p>
            </div> 
          );
        }
      
        return null;
      };

  return (
    <Box sx={{ height: "100%", width: "100%"}}>
         {data && <ResponsiveContainer>
            <BarChart  data={data?.pages[0].data.results}>
              <XAxis  type="category" dataKey="scannerId" />
              <YAxis />
              <Tooltip content={<CustomTooltip />}/>
              <Legend />
              <Bar barSize={40} dataKey="success" stackId="a" fill="#82ca9d" />
              <Bar barSize={40} dataKey="noRead" stackId="a" fill="#9b2226" />
            </BarChart>
          </ResponsiveContainer>}
        </Box>
  )
}
export default memo(ScannerGraph)