import { Box } from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import {
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Area,
  ComposedChart,
} from "recharts";

const Charts = ({ data, }) => {
  const [linesRendered, setLinesRendered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const chartRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once the chart is visible
        }
      },
      {
        threshold: 0.1, // Adjust as needed
      }
    );

    if (chartRef.current) {
      observer.observe(chartRef.current);
    }

    return () => {
      if (chartRef.current) {
        observer.unobserve(chartRef.current);
      }
    };
  }, []);

  const handleLineRender = () => {
    setLinesRendered(true);
  };

  const [opacity, setOpacity] = useState({
    amount: 1,
    total: 1,
  });

  return (
    <Box style={{ height: "97%", width: "100%", cursor:"pointer"}}>
      <ResponsiveContainer>
        <ComposedChart
          data={data.occurrences}
          reverseStackOrder
        >
          <XAxis type="category" dataKey="dateRange" />
          <YAxis
            tickLine={false}
            strokeOpacity={opacity.amount}
            dataKey="amount"
            stroke="#5DA0B3"
            orientation="left"
            yAxisId={1}
          />
          <YAxis
            strokeOpacity={opacity.total}
            dataKey="total"
            stroke="#3142C6"
            orientation="right"
            yAxisId={2}

          />
          <Tooltip />
          <Area
            strokeOpacity={opacity.total}
            type="monotone"
            stroke="#3142C6"
            strokeWidth={3}
            dot={true}
            dataKey="total"
            yAxisId={2}
            stackId="1"
          />
          <Area
            strokeOpacity={opacity.amount}
            strokeWidth={3}
            type="monotone"
            stroke="black"
            fill="black"
            dot={true}
            dataKey="amount"
            yAxisId={1}
            stackId="1"
            onAnimationEnd={handleLineRender}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default memo(Charts);
