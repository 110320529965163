import Guacamole from "guacamole-common-js";
import { useEffect, useRef, useCallback, useState } from "react";
import toast from "react-hot-toast";
import ErrorComponent from "../../Shared/UI/ErrorComponent";

// Clipboard functionality
const clipboard = {
  cache: null,

  async getLocalClipboard() {
    if (navigator.clipboard && navigator.clipboard.readText) {
      try {
        const text = await navigator.clipboard.readText();
        console.log("Local clipboard read:", text);
        return {
          type: "text/plain",
          data: text,
        };
      } catch (error) {
        console.error("Failed to read from clipboard:", error);
      }
    }
  },

  async setLocalClipboard(data) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        if (data.type === "text/plain") {
          await navigator.clipboard.writeText(data.data);
          console.log("Local clipboard set:", data.data);
        }
      } catch (error) {
        console.error("Failed to write to clipboard:", error);
      }
    }
  },

  setRemoteClipboard(client) {
    if (!this.cache) {
      console.log("No cache to send to remote clipboard");
      return;
    }

    let writer;
    const stream = client.createClipboardStream(this.cache.type);

    try {
      if (typeof this.cache.data === "string") {
        writer = new Guacamole.StringWriter(stream);
        writer.sendText(this.cache.data);
        writer.sendEnd();
        console.log("Sent text to remote clipboard");
      } else {
        writer = new Guacamole.BlobWriter(stream);
        writer.oncomplete = () => writer.sendEnd();
        writer.sendBlob(this.cache.data);
        console.log("Sent blob to remote clipboard");
      }
    } catch (error) {
      console.error("Error setting remote clipboard:", error);
    }
  },

  onClipboard(stream, mimetype) {
    try {
      if (/^text\//.exec(mimetype)) {
        const reader = new Guacamole.StringReader(stream);
        let data = "";
        reader.ontext = (text) => (data += text);
        reader.onend = () => {
          this.setLocalClipboard({
            type: mimetype,
            data: data,
          });
          console.log("Received text from remote clipboard");
        };
      } else {
        const reader = new Guacamole.BlobReader(stream, mimetype);
        reader.onend = () => {
          this.setLocalClipboard({
            type: mimetype,
            data: reader.getBlob(),
          });
          console.log("Received blob from remote clipboard");
        };
      }
    } catch (error) {
      console.error("Error processing remote clipboard data:", error);
    }
  },

  install(client) {
    this.getLocalClipboard().then((data) => {
      this.cache = data;
      console.log("Initial local clipboard cache:", data);
    });

    const update = () => {
      this.getLocalClipboard().then((data) => {
        this.cache = data;
        this.setRemoteClipboard(client);
      });
    };

    window.addEventListener("load", update, true);
    window.addEventListener("copy", update);
    window.addEventListener("cut", update);
    window.addEventListener(
      "focus",
      (e) => {
        if (e.target === window) {
          update();
        }
      },
      true
    );

    console.log("Clipboard event listeners installed");
  },

  uninstall() {
    window.removeEventListener("load", this.update, true);
    window.removeEventListener("copy", this.update);
    window.removeEventListener("cut", this.update);
    window.removeEventListener("focus", this.update, true);
    console.log("Clipboard event listeners uninstalled");
  },
};

export default function MachineScreen({ token }) {
  const myRef = useRef(null);
  const clientRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");

  const updateScale = useCallback(() => {
    if (!clientRef.current) return;
    const display = clientRef.current.getDisplay();
    const remoteWidth = display.getWidth();
    const remoteHeight = display.getHeight();

    if (remoteWidth === 0 || remoteHeight === 0) {
      console.log("Remote display size not ready yet");
      return;
    }
    const pageWidth = window.innerWidth;
    const pageHeight = window.innerHeight - 100;

    const xscale = pageWidth / remoteWidth;
    const yscale = pageHeight / remoteHeight;

    const newScale = Math.min(xscale, yscale);

    display.scale(newScale);
    console.log("Display scaled to:", newScale);
  }, []);

  useEffect(() => {
    const tunnel = new Guacamole.WebSocketTunnel(
      "wss://csp.par-innovations.com/dialin"
    );
    const client = new Guacamole.Client(tunnel);
    clientRef.current = client;

    const mouse = new Guacamole.Mouse(client.getDisplay().getElement());

    clipboard.install(client);

    client.onclipboard = clipboard.onClipboard.bind(clipboard);

    client.onstatechange = (state) => {
      console.log("State changed to: " + state);
    };

    client.onerror = (error) => {
      console.error("Guacamole error: " + JSON.stringify(error));
      setErrorMessage(error.message);
      toast.error(error.message);
    };

    if (myRef.current) {
      const handleDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
      };

      window.addEventListener("dragover", handleDragOver);

      myRef.current.appendChild(client.getDisplay().getElement());

      mouse.onmousemove = (state) => {
        state.x /= clientRef.current.getDisplay().getScale();
        state.y /= clientRef.current.getDisplay().getScale();
        client.sendMouseState(state);
      };

      mouse.onmousedown = mouse.onmouseup = (state) => {
        client.sendMouseState(state);
      };

      const keyboard = new Guacamole.Keyboard(window);

      keyboard.onkeydown = function (keysym) {
        client.sendKeyEvent(1, keysym);
      };

      keyboard.onkeyup = function (keysym) {
        client.sendKeyEvent(0, keysym);
      };

      client.connect("token=" + token);

      const display = client.getDisplay();
      display.onresize = updateScale;
      window.addEventListener("resize", updateScale);

      return () => {
        display.onresize = null;
        window.removeEventListener("resize", updateScale);
        window.removeEventListener("dragover", handleDragOver);

        keyboard.onkeydown = null;
        keyboard.onkeyup = null;

        mouse.onmousemove = null;
        mouse.onmousedown = null;
        mouse.onmouseup = null;

        clipboard.uninstall();
        client.disconnect();
      };
    }
  }, [updateScale, token]);

  if (errorMessage) {
    return <ErrorComponent message={errorMessage} />;
  }

  return (
    <div className="">
      <div
        ref={myRef}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          height: "100%",
          zIndex: 10,
          cursor: "none",
        }}
      />
    </div>
  );
}
