import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useGetOperational } from "../../../../hooks/hooks.jsx";
import moment from "moment";
import configData from "../../../../config.js";
import Lottie from "lottie-react";
import animationData from "./presets/boxes.json";
import { useNavigate } from "react-router-dom";

export default function TodaysStats() {
  const project = useSelector((state) => state.project);
  // Set selectedDate to yesterday
  const selectedDate = moment().format("YYYY-MM-DD Z");
  const animation = useRef();
  const { data, isLoading } = useGetOperational({
    endpoint: `${configData.SERVER_URL}operational/${project.id}`,
    refetchInterval: 20000,
    requestId: `${project.id}-today-operational`,
    parameters: { selectedDate },
  });

  const [previousCount, setPreviousCount] = useState(0);
  const [newLoadCarriers, setNewLoadCarriers] = useState(0);
const navigate = useNavigate()
  const decisionPoint = data?.filter((decisionPoint) =>
    decisionPoint.translation.includes("^")
  )[0];

  // Effect to calculate the new load carriers processed
  useEffect(() => {
    if (decisionPoint) {
      const currentCount = decisionPoint.occurrences.at(-1).total;

      // Calculate the difference
      if (previousCount !== 0) {
        setNewLoadCarriers(currentCount - previousCount);
      }

      // Update the previous count
      setPreviousCount(currentCount);
    }
  }, [data, decisionPoint, previousCount]);

  // Effect to trigger the animation multiple times based on newLoadCarriers
  useEffect(() => {
    if (newLoadCarriers > 0) {
      const playAnimation = async () => {
        for (let i = 0; i < newLoadCarriers; i++) {
          animation.current.goToAndStop(0, true); // Reset animation to start
          animation.current.play(); // Play the animation
          // Wait for the animation duration + a little extra time for the next play
          await new Promise(resolve => setTimeout(resolve, 1000)); // Adjust the timeout duration if necessary
        }
      };
      playAnimation();
    }
  }, [newLoadCarriers]);
if(isLoading){
    return(
        <div style={{ height: "100%", width: "100%", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "space-evenly", position: "relative", background: "#508c9e", color: "white" }}>
            Loading today's performance...
            </div>
    )
}
if(data.length===0){
    return(
        <div style={{ height: "100%", width: "100%", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "space-evenly", position: "relative", background: "#508c9e", color: "white" }}>
            No data
            </div>
    )
}
  return (
    <div onClick={()=>navigate(`/${project.name.replace(/ /g, "-")}/Operational`)} style={{ height: "100%", width: "100%", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "space-evenly", position: "relative", background: "#508c9e", color: "white" }}>
      <span style={{position:"absolute",top:0,left:0,padding:"3px"}}> Load carriers processed</span>
      <strong style={{fontSize:"34px", background:"#508c9e"}}>{decisionPoint?.occurrences.at(-1).total}</strong>
      <Lottie
        style={{ height: "100%" }}
        rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
        lottieRef={animation}
        animationData={animationData}
        autoPlay={false}
        loop={false}
        />
        </div>
  );
}
