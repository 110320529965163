import styled from "@emotion/styled";
import { Box, Breadcrumbs, Divider } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ProjectsContent from "./ProjectsContent";

const Projects = () => {
  useEffect(() => {
    sessionStorage.removeItem("project");
  }, []);

  return (
    <>
      <StyledBreadcrumbs maxItems={3} aria-label="breadcrumb">
        <StyledLink last="true" to="/">
          Projects
        </StyledLink>
      </StyledBreadcrumbs>
      <Divider />

        <ProjectsContent />
    </>
  );
};

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin: 10px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  color: ${(props) => (props.last === "true" ? "black" : "rgba(0,0,0,0.6)")};
  &:hover {
    text-decoration: underline;
  }
`;

export default Projects;
