import axios from 'axios';
import React, { useEffect, useState } from 'react';
import config from '../../../../config';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { Circle } from '@mui/icons-material';

const getColor = (status) => {
    switch (status) {
        case "Synced":
            return "green";
        case "Syncing...":
            return "orange";
        default:
            return "red";
    }
};

const fetchProjects = async () => {
    const response = await axios.get(`${config.SERVER_URL}Projects`);
    return response.data;
};

export default function ConnectionStatus() {
    const project = useSelector((state) => state.project);
    const [scale, setScale] = useState(1);

    // Use React Query for polling
    const {
        data: projects,
        isLoading,
    } = useQuery(["projects"], fetchProjects, {
        refetchInterval: 5000, // Poll every 5000ms (5 seconds)
    });

    const connectionStatus = projects?.results.find(projectData => projectData.id === project.id)?.connectionStatus;

    // Effect to handle continuous animation
    useEffect(() => {
        const interval = setInterval(() => {
            setScale((prevScale) => (prevScale === 1 ? 1.5 : 1)); // Toggle between 1 and 1.5
        }, 1000); // Change scale every second

        // Cleanup interval on unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", fontSize: "32px", position: "relative" }}>
            <strong>
                {isLoading ? "Loading..." : `Data ${connectionStatus}`}
            </strong>
            <Circle
                sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: getColor(connectionStatus), // Use helper function for color
                    width: "15px",
                    marginX: "5px",
                    transform: `scale(${scale})`, // Apply the scale for animation
                    transition: 'transform 0.5s ease', // Smooth transition for scaling
                }}
            />
        </div>
    );
}
